import { useCallback } from 'react';
import ReactGA from 'react-ga4';
import { useEventBindings } from './use-event-bindings';
import { useSendJitsuEvent } from './use-send-jitsu-event';

export const usePerformEvent = () => {
  const eventBindings = useEventBindings();
  const sendJitsuEvent = useSendJitsuEvent();

  return useCallback((event) => {
    try {
      const { detail } = event;
      if (!detail) {
        return;
      }
      const { type, name, ...rest } = detail;
      eventBindings[name](rest);
      ReactGA.send({
        hitType: 'pageview',
        page: `/xps/${name}/${rest.id}`,
        title: `XPs > ${name}`,
      });
      sendJitsuEvent('Navigation > Open XP', { xpId: rest.id, xpType: name });
    } catch (err) {
      console.error('Event doesn\'t exist', err);
    }
  }, [eventBindings, sendJitsuEvent]);
};
