import React from 'react';
import PropTypes from 'prop-types';
import { FixedRightSpace, StyledInput, StyledWrapper } from './styles';

export const TextInput = ({ children, ...rest }) => (
  <StyledWrapper>
    <StyledInput {...rest} />
    {children && (
      <FixedRightSpace>
        {children}
      </FixedRightSpace>
    )}
  </StyledWrapper>
);

TextInput.propTypes = {
  stretch: PropTypes.bool,
};
