import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProjectAPI } from '../../../api/project';

export const fetchProjectInfoThunk = createAsyncThunk(
  'system/fetch-project-info',
  async () => ProjectAPI.getInfo(),
);

export const fetchProjectNavigationThunk = createAsyncThunk(
  'system/fetch-project-navigation',
  async (id) => ProjectAPI.getNavigation(id),
);
