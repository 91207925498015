import React from 'react';
import PropTypes from 'prop-types';
import {
  ShareIcon, StyledDescription, StyledTitle, StyledWrapper,
} from './styles';

export const LiveFeedItem = ({
  author, nickName, text, createdAt, avatar,
}) => (
  <StyledWrapper>
    <img src={avatar} alt="avatar" />
    <div>
      <StyledTitle>
        {author}
        <span>{nickName}</span>
      </StyledTitle>
      <StyledDescription>
        {text}
        <span>{createdAt}</span>
      </StyledDescription>
    </div>
    <ShareIcon>
      <img src="/icons/chare-live-feed.svg" alt="icon" />
    </ShareIcon>
  </StyledWrapper>
);

LiveFeedItem.propTypes = {
  author: PropTypes.string.isRequired,
  nickName: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};
