import { useCallback } from 'react';

export const useDispatch3dvistaNavigationEvent = () => useCallback((eventId) => {
  const event = new CustomEvent('3dvista', {
    detail: {
      type: 'navigation',
      eventId,
    },
  });
  document.dispatchEvent(event);
}, []);
