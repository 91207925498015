import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import authReducer from '../features/auth/store/auth.slice';
import systemReducer from '../features/system/store/system.slice';
import liveStreamReducer from '../features/live-stream/store/live-stream.slice';
import gameSliceReducer from '../features/game/store/game.slice';
import introTourReducer from '../features/intro-tour/store/intro-tour.slice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedGamesReducer = persistReducer(persistConfig, gameSliceReducer);

export const store = configureStore({
  reducer: {
    auth: authReducer,
    introTour: introTourReducer,
    game: persistedGamesReducer,
    liveStream: liveStreamReducer,
    system: systemReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export const persistor = persistStore(store);

window.store = store;
