import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { selectLoading, selectMessages } from '../store/live-stream.selectors';
import { postMessageThunk } from '../store/live-stream.thunk';

export const useAddMessage = () => {
  const dispatch = useDispatch();
  return useCallback((message) => {
    dispatch(postMessageThunk(message));
  }, [dispatch]);
};

export const useMessages = () => useSelector(selectMessages);
export const useMessagesLoading = () => useSelector(selectLoading);
