import { useCallback } from 'react';
import { EVENT_COMPLETED } from '../config/features.config';

export const useDispatchCompleteEvent = () => useCallback(({ id, name, payload = {} }) => {
  const event = new CustomEvent(EVENT_COMPLETED, {
    detail: {
      id,
      name,
      payload,
    },
  });
  document.dispatchEvent(event);
}, []);
