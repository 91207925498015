import React from 'react';
import { useSearchParams } from 'react-router-dom';
import CompetitionFormContainer
  from '../../features/competition/containers/competition-form-container';

const CompetitionPageComponent = () => {
  const [params] = useSearchParams();

  return <CompetitionFormContainer typeformId={params.get('typeformId')} />;
};

export const CompetitionPage = CompetitionPageComponent;
