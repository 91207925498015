import styled from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const LogoHeader = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 70px;
  height: 70px;
  user-select: none;
  background: ${({ theme }) => theme.colors.$dark900};
  border: 1px solid ${({ theme }) => theme.colors.$dark700};
  box-shadow: 0 20px 30px rgba(23, 24, 29, 0.3);
  border-radius: 0 0 50px 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({ topLayer, theme }) => topLayer
    ? theme.zIndex.topLayer
    : theme.zIndex.header};
  padding: 16px 8px;
  
  & img {
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 20px;
  }
  
  ${media.sm`
    width: 100px;
    height: 100px;
  `}
`;
