import styled, { css } from 'styled-components';
import { FlexBox } from '../../../../ui/atoms/flex-box';
import { Typography } from '../../../../ui/atoms/typography';
import { media } from '../../../../ui/mixins/media';
import { TransparentTextInput } from '../../../../ui/atoms/transparent-text-input';

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.$white};
  position: relative;
  margin-bottom: 12px;
  padding: 12px 16px;

  ${media.md`
  border-bottom: none;
  padding: 22px 0 22px 16px;
  `}
`;

export const StyledTitle = styled(FlexBox)`
  margin-bottom: 8px;

  & > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

export const StyledName = styled(Typography)`
  display: flex;
  align-items: center;

  & img {
    margin: 0 4px 0 10px;
  }

  & span {
    margin-left: 5px;
    color: #8996AB;
    font-weight: 400;
    font-size: 10px;
  }
`;

export const StyledCount = styled(Typography)`
  margin-left: auto;
  opacity: 0.5;

  ${media.md(css`
    font-weight: 500;
    font-size: 13px;
  `)}
`;

export const StyledInout = styled(TransparentTextInput)`
  margin-bottom: 12px;

  ${media.md(css`
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  `)}
`;

export const StyledTags = styled.div`
  position: relative;
  overflow: hidden;
  flex: 1;

  & > div {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    background: linear-gradient(180deg, rgba(43, 45, 55, 0) -228.12%, #272933 94.44%);
    filter: blur(4px);
    transform: matrix(0, 1, 1, 0, 0, 0);
  }
`;

export const StyledTag = styled.div`
  display: inline-block;
  background: rgba(57, 134, 208, 0.1);
  border: 1px solid rgba(57, 134, 208, 0.5);
  border-radius: 6px;
  padding: 3px 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #297AFF;
  margin-right: 4px;

  ${media.md(css`
    font-size: 12px;
    padding: 6px 10px;
  `)}
`;
