import React from 'react';
import { StyledWrapper, StyledSpan, StyledInput } from './checkbox.styles';

export const Checkbox = React.forwardRef((props, ref) => (
  <StyledWrapper>
    <StyledInput
      {...props}
      type="checkbox"
      ref={ref}
    />
    <StyledSpan />
  </StyledWrapper>
));

Checkbox.displayName = 'Checkbox';
