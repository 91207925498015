import { useContext } from 'react';
import { JitsuContext } from '@jitsu/react';
import { useTranslation } from 'react-i18next';
import { PROJECT_ID } from '../../../config/common.config';

export const useSendJitsuEvent = () => {
  const jitsuContextValue = useContext(JitsuContext);
  const { i18n } = useTranslation();

  return (name, payload = {}) => {
    jitsuContextValue?.track(`${name} (${payload.xpType})`, {
      ...payload,
      projectId: PROJECT_ID,
      prefLocale: i18n.language,
    });
  };
};
