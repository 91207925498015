import { useCallback, useEffect } from 'react';
import { useHandleEvent } from '../../../app/hooks/use-handle-event';
import { EVENT_COMPLETED, FEATURE_NAMES } from '../../../../config/features.config';
import { useCompleteGame } from '../../../game/hooks/use-earned-points';
import { useCheckEventsQueue } from '../../../system/hooks/use-check-events-queue';
import { useSendJitsuEvent } from '../../../app/hooks/use-send-jitsu-event';

export const EventManagerContainer = () => {
  const handleEvent = useHandleEvent();
  const complete = useCompleteGame();
  const checkEventsQueue = useCheckEventsQueue();
  const sendJitsuEvent = useSendJitsuEvent();

  const handle3DVEvent = useCallback(async (event) => {
    const { detail } = event;
    if (!detail) {
      return;
    }

    const { type, eventId } = detail;
    if (type !== 'navigation' && !eventId) {
      return;
    }
    handleEvent(eventId);
  }, [handleEvent]);

  const handleCompletedEvent = useCallback(async (event) => {
    const { id, name, payload } = event.detail || {};
    if (Object.values(FEATURE_NAMES).includes(name)) {
      complete({
        name,
        id,
        payload,
      });
      sendJitsuEvent('Navigation > Close XP', { xpId: id, xpType: name });
    }
    checkEventsQueue();
  }, [checkEventsQueue, complete, sendJitsuEvent]);

  useEffect(() => {
    document.addEventListener('3dvista', handle3DVEvent);

    return () => {
      document.removeEventListener('3dvista', handle3DVEvent);
    };
  }, [handle3DVEvent, handleEvent]);

  useEffect(() => {
    document.addEventListener(EVENT_COMPLETED, handleCompletedEvent);

    return () => {
      document.removeEventListener(EVENT_COMPLETED, handleCompletedEvent);
    };
  }, [handleCompletedEvent]);

  return null;
};
