import React, { useCallback, useState } from 'react';
import { useIntroTourStoreApi } from '../../hooks/use-intro-tour-store-api';
import { Notification } from '../../components/notification';
import { StyledWrapper } from './styles';
import { useDispatch3dvistaNavigationEvent } from '../../../../hooks/use-dispatch-3dvista-navigation-event';

const initialRemovedNotifications = localStorage.getItem('removedNotifications') ?? '[]';

export const NotificationsContainer = () => {
  // eslint-disable-next-line max-len
  const [removedNotifications, setRemovedNotifications] = useState(JSON.parse(initialRemovedNotifications));

  const { notifications } = useIntroTourStoreApi();
  const dispatchEvent = useDispatch3dvistaNavigationEvent();

  const closeNotification = useCallback((id) => {
    setRemovedNotifications((prev) => [...prev, id]);
    localStorage.setItem('removedNotifications', JSON.stringify([...removedNotifications, id]));
  }, [removedNotifications]);

  const handleClick = useCallback((notification) => {
    closeNotification(notification.locationId);
    if (notification.notificationEventId) {
      dispatchEvent(notification.notificationEventId);
    }
  }, [closeNotification, dispatchEvent]);

  // eslint-disable-next-line max-len
  const filteredShowNotifications = notifications.filter(({ locationId }) => !removedNotifications.includes(locationId));

  if (!filteredShowNotifications.length) {
    return null;
  }

  return (
    <StyledWrapper>
      {filteredShowNotifications.map((notification) => (
        <Notification
          key={notification.locationId}
          {...notification}
          closeNotification={closeNotification}
          onClick={() => handleClick(notification)}
        />
      ))}
    </StyledWrapper>
  );
};
