import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import hash from 'hash-parse';

import {
  LANGUAGES,
  PROJECT_ID,
  STORAGE_KEYS,
  PROJECT_API_KEY,
  CONTENT_API_URL,
} from './common.config';

const searchParams = new URLSearchParams(window.location.search);

const hashParams = hash.parse(window.location.href);

const langInUri = searchParams.get('language') || hashParams.language;

const langFromLs = localStorage.getItem(STORAGE_KEYS.lang);

const DEFAULT_LANG = LANGUAGES.includes(langInUri) ? langInUri : (langFromLs || 'en');

if (langInUri) {
  localStorage.setItem(STORAGE_KEYS.lang, langInUri);
  window.history.replaceState(
    undefined,
    '',
    `${window.location.pathname}#language=${langInUri}`,
  );
}

if (langFromLs) {
  window.location.hash = `#language=${langFromLs}`;
}

i18n
  // load translation using http -> see /public/locales
  // (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? =>
  // https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    lng: DEFAULT_LANG,
    backend: {
      loadPath: `${CONTENT_API_URL}/xps/${PROJECT_ID}/{{ns}}/{{lng}}`,
      customHeaders: {
        'xspace-api-key': PROJECT_API_KEY,
      },
    },
    supportedLngs: LANGUAGES,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
