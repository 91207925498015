import styled, { css } from 'styled-components';
import { media } from '../../mixins/media';

export const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  user-select: none;
  background: ${({ theme }) => theme.colors.$primary700};
  border: 3px solid ${({ theme }) => theme.colors.$primary200};
  box-shadow: 0 4px 0 ${({ theme }) => theme.colors.$primary900},
    inset 0 4px 6px ${({ theme }) => theme.colors.$primary800};
  border-radius: 12px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  line-height: 24px;
  padding: 4px 16px;
  text-align: center;
  color: ${({ theme }) => theme.colors.$white};

  text-shadow: 0 2px 4px ${({ theme }) => theme.colors.$primary800};
  transition: box-shadow 150ms ease-in-out, background-color 150ms ease-in-out, border-color 150ms ease-in-out;

  ${({ variant, theme }) => variant === 'white'
    && css`
      background: ${theme.colors.$white};
      border: 3px solid ${theme.colors.$light200};
      box-shadow: 0 4px 0 ${theme.colors.$light300}, inset 0 4px 4px ${theme.colors.$light300};
      text-shadow: none;
      color: ${theme.colors.$dark900};

      & svg {
        color: ${theme.colors.$primary200};
      }
    `}
  ${media.sm`
    height: 48px;
    padding: 8px 24px;
  `}
  ${media.md`
    height: 56px;
    padding: 12px 24px;
  `}
  &:hover {
    box-shadow: 0 4px 0 ${({ theme }) => theme.colors.$primary900}, 
      0 10px 15px ${({ theme }) => theme.colors.$dark700}, 
      inset 0 4px 6px ${({ theme }) => theme.colors.$primary800};

    ${({ variant, theme }) => variant === 'white'
    && css`
    box-shadow: 0 4px 0 ${theme.colors.$light300}, 
      0 10px 15px ${theme.colors.$dark700}, 
      inset 0 4px 4px ${theme.colors.$light300};
      `}
  }

  &:focus {
    background: ${({ theme }) => theme.colors.$primary900};
    border: 3px solid ${({ theme }) => theme.colors.$primary200};

    ${({ variant, theme }) => variant === 'white'
    && css`
        background: ${theme.colors.$light300};
        border: 3px solid ${theme.colors.$light200};
        box-shadow: 0 4px 0 ${theme.colors.$light300}, inset 0 4px 4px ${theme.colors.$light300};

        & svg {
          color: ${theme.colors.$white};
        }
      `}
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  & img + *,
  & * + img,
  & svg + *,
  & * + svg {
    margin-left: 10px;
  }
`;
