import styled, { css } from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.videoContent};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow-y: auto;
  padding: 90px 8px 0;

  ${media.sm(css`
    padding-left: calc(50vw - 308px);
    padding-right: calc(50vw - 308px);
    padding-top: 140px;
  `)}
  
  ${media.lg(css`
    padding-left: calc(50vw - 550px);
    padding-right: calc(50vw - 550px);
    padding-top: 140px;
  `)}
`;

export const StyledContent = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.$white};
  background: ${({ theme }) => theme.colors.$dark900};
  border: 3px solid ${({ theme }) => theme.colors.$dark700};
  box-shadow: 0 10px 0 ${({ theme }) => theme.colors.$dark800};
  border-radius: 20px;
  padding: 12px;
  text-align: center;
  
  & iframe {
    border-radius: 16px;
  }
  
  & button {
    margin: 0 auto 24px;
  }
  
  ${media.lg(css`
    display: flex;
    
    & > * {
      flex: 1;
    }
        
    & > * + * {
      margin-left: 20px;
    }
    
    & button {
      display: none;
    }
  `)}
`;

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 16px 0 12px;
  color: ${({ theme }) => theme.colors.$white};
  
  ${media.sm(css`
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  `)}
`;

export const StyledDescription = styled.div`
  font-size: 10px;
  line-height: 15px;

  ${media.sm(css`
    text-align: left;
  `)}
  
  ${media.md(css`
    font-size: 12px;
    line-height: 18px;
  `)}
`;

export const StyledFormWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  padding: 8px;

  ${media.lg(css`
    position: relative;
  `)}
`;

export const StyledContentTop = styled.div`
  display: flex;
  flex-direction: column;

  ${media.sm(css`
    flex-direction: row;
    align-items: center;
    
    & button {
      margin: 0 auto;
    }
  `)}
`;

export const StyledAdvList = styled.div`
  margin: 10px 0 18px;
  
  & p {
    font-size: 10px;
    line-height: 15px;
  }
  
  &  > p + p {
    margin-top: 4px;
  }
  
  ${media.md`
    text-align: left;
    & p {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
    }
  `}
`;
