import React, {
  useCallback, useEffect, useRef,
} from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { useTranslation } from 'react-i18next';

export const VimeoPlayer = (
  {
    config,
    cc,
    videoEmbedId,
    muted,
    play,
    onPlay,
    onPlayError,
    onEnd,
  },
) => {
  const initRef = useRef(false);
  const playerRef = useRef(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const locale = i18n.language || 'en'; // fallback to 'en' if no language defined

    if (cc) {
      // Enable captions
      playerRef.current?.enableTextTrack(locale).catch((error) => console.log('Enable CC Error:', error));
    } else {
      // Disable captions
      playerRef.current?.disableTextTrack().catch((error) => console.log('Disable CC Error:', error));
    }
  }, [cc, i18n.language]);

  const onReady = useCallback((player) => {
    playerRef.current = player;
    if (!play) {
      return;
    }
    playerRef.current.play().then(() => {
      onPlayError(false);
      onPlay(true);
    }).catch(() => {
      onPlayError(true);
      onPlay(false);
    });
    console.log('Player Ready event fired');
  }, [onPlay, onPlayError, play]);

  // Debug : Log current locale to determine if this is set when enabling `textTrack`
  console.log('i18n.language:', i18n.language);

  // Debug: Log each time the textTrack state is toggled on/off to debug the CC button
  useEffect(() => {
    console.log('CC:', cc);
    console.log('TextTrack:', config.textTrack);
  }, [cc, config.textTrack]);

  useEffect(() => {
    if (muted) {
      playerRef.current?.setVolume(0);
    } else {
      playerRef.current?.setVolume(1);
    }
  }, [muted]);

  useEffect(() => {
    if (!initRef.current) {
      initRef.current = true;
      return;
    }
    if (play) {
      playerRef.current?.play();
    } else {
      playerRef.current?.pause();
    }
  }, [play]);

  return (
    <Vimeo
      playsInline
      onReady={onReady}
      onPlay={() => onPlay(true)}
      onPause={() => onPlay(false)}
      muted={muted}
      video={videoEmbedId}
      onError={console.error}
      loop={config.loop}
      controls={config.controls}
      showByline={config.showByline}
      showPortrait={config.showPortrait}
      showTitle={config.showTitle}
      keyboard={config.keyboard}
      pip={config.pip}
      dnt={config.dnt}
      textTrack={cc ? i18n.language : undefined}
      onEnd={onEnd}
    />
  );
};
