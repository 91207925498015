import styled from 'styled-components';

export const StyledSpan = styled.span`
  pointer-events: none;
  position: relative;
  display: block;
  width: 18px;
  height: 18px;

  border: 2px solid #334155;
  border-radius: 4px;

  transition: border-color 150ms;
  
  &:after,
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    background-color: ${({ theme }) => theme.colors.$white};
    width: 2px;
    border-radius: 2px;
    opacity: 0;
    transition: opacity 150ms;
  }
  
  &:after {
    transform: rotate(-40deg);
    height: 5px;
    left: -6px;
    top: 5px;
  }
  
  &:before {
    transform: rotate(40deg);
    height: 12px;
    right: -4px;
  }
`;

export const StyledWrapper = styled.label`
  display: inline-flex;
  width: 18px;
  flex: 0 0 18px;
  cursor: pointer;

  &:hover ${StyledSpan} {
    border-color: #316CF4;
  }

  &:active ${StyledSpan} {
    border-color: #316CF4;
  }
`;

export const StyledInput = styled.input`
  display: none;

  &:disabled + ${StyledSpan} {
    border-color: grey !important;
  }
  
  &:checked + ${StyledSpan} {
    background: #316CF4;
    border-color: #316CF4;
    
    &:after,
    &:before {
      opacity: 1;
    }
  }
`;
