import styled from 'styled-components';

export const StyledSvg = styled.svg`
  color: ${({ color, theme }) => color || theme.colors.$white};
  flex: ${({ width }) => `0 0 ${width}px`};
  
  &:hover {
    color: ${({ activeColor, theme }) => activeColor || theme.colors.$white}
  }
`;
