import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledRoot,
  StyledInnerWrapper,
  StyledIcon,
  StyledUserIcon,
  StyledWrapper,
  StyledTopHexagon,
} from './styles';
import { useScreenDetect } from '../../../hocs/use-screen-detect';
import { ButtonClose } from '../../atoms/button-close';
import { HexagonLayoutIcon } from '../../atoms/hexagon-layout-icon';

export const ModalLayout = ({
  icon, children, onClose, className,
}) => {
  const screen = useScreenDetect();

  const titleIcon = screen.xs ? '/icons/modal-icon-mobile.svg' : '/icons/modal-icon.svg';

  return (
    <StyledRoot>
      <StyledWrapper className={className}>
        {!!icon && (
          <StyledTopHexagon>
            <HexagonLayoutIcon />
            <StyledIcon>
              <StyledUserIcon>
                <img src={icon} />
              </StyledUserIcon>
              <img src={titleIcon} alt="" />
            </StyledIcon>
          </StyledTopHexagon>
        )}
        {onClose && (
          <ButtonClose onClick={onClose}>
            <img src="/icons/close_round_icon.svg" alt="close" />
          </ButtonClose>
        )}
        <StyledInnerWrapper topBorder={!icon}>
          <div>{children}</div>
        </StyledInnerWrapper>
      </StyledWrapper>
    </StyledRoot>
  );
};

ModalLayout.propTypes = {
  onClose: PropTypes.func,
  icon: PropTypes.string,
  className: PropTypes.string,
};
