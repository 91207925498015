import styled from 'styled-components';

export const StyledTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: ${({ theme }) => theme.colors.$white};
  margin-bottom: 10px;

  & i {
    color: #4D9FEE;
  }
`;

export const StyledImages = styled.div`
  user-select: none;

  & img {
    width: calc(100% - 30px);
    max-height: 92px;
  }

  & img:last-child {
    margin: -30px 0 0 30px;
  }
`;
