import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { StyledWrapper } from './styles';
import { FullscreenModalLayout } from '../../../../ui/layouts/fullscreen-modal-layout';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';

const WebViewModalComponent = ({ closeModal, id }) => {
  const { t } = useTranslation(`iframes/${id}`);
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  useEffect(() => {
    dispatchCompleteEvent({
      id,
      name: FEATURE_NAMES.iframe,
    });
  }, [dispatchCompleteEvent, id]);

  return (
    <FullscreenModalLayout onClose={closeModal}>
      <StyledWrapper>
        <iframe
          src={t('content.sourceUrl')}
          allow={t('content.permissions')}
          title="webview"
        />
      </StyledWrapper>
    </FullscreenModalLayout>
  );
};

export const WebViewModal = modalRegistry.register({
  id: 'WebViewModal',
  component: WebViewModalComponent,
});
