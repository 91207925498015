import styled, { css } from 'styled-components';
import { FlexBox } from '../../../../ui/atoms/flex-box';
import { media } from '../../../../ui/mixins/media';

export const StyledWrapper = styled(FlexBox)`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 0;
  position: relative;

  & > img {
    width: 24px;
    height: 24px;
  }

  & > div {
    margin-left: 10px;
  }

  ${media.md(css`
    border-bottom: none;
    & > img {
      width: 44px;
      height: 44px;
    }
  `)}
`;

export const ShareIcon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(50%);

  ${media.md`
  top:20px;
  transform:translateY(0);
  `}

  & img {
    width: 11px;
    height: 11px;

    ${media.md`
    width: 14px;
    height: 14px;
    `}
  }
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.$white};
  margin-bottom: 5px;

  & span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #8996AB;
    margin-left: 5px;
  }
`;

export const StyledDescription = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: ${({ theme }) => theme.colors.$white};

  & span {
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #8996AB;
    margin-left: 5px;
  }

  ${media.md(css`
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    margin-top: 5px;

    & span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      display: block;
      margin-top: 10px;
      margin-left: 0;
    }
  `)}
`;
