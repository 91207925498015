import styled from 'styled-components';

export const StyledWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.zIndex.modal};
  opacity: 0;
  visibility: hidden;
  transition: visibility 200ms, opacity 200ms;
  
  ${({ active }) => active && `
    visibility: visible;
    opacity: 1;
  `};
`;
