import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, HotWrapper } from './styled';
import { Typography } from '../../../ui/atoms/typography';
import { useScreenDetect } from '../../../hocs/use-screen-detect';
import { useNavigation } from '../../system/hooks/use-system-store';
import { useHandleEvent } from '../../app/hooks/use-handle-event';

export const HotContainer = ({ onClose }) => {
  const navigation = useNavigation();
  const { i18n } = useTranslation();
  const handleEvent = useHandleEvent();

  const navItems = navigation[i18n?.language]?.quicknavItems ?? [];
  const title = navigation[i18n?.language]?.navFooterPosition2Name ?? 'What’s Hot';

  const { isMobile } = useScreenDetect();

  const onEventClick = useCallback((eventId) => {
    handleEvent(eventId);
    onClose();
  }, [handleEvent, onClose]);

  return (
    <Container>
      <Typography tag="div" size={isMobile ? 'h4' : 'h2'} bold={isMobile} extraBold={!isMobile}>
        {title}
      </Typography>
      <HotWrapper>
        {navItems?.map((navItem) => (
          <Typography
            key={navItem.name}
            tag="div"
            size={isMobile ? 'h3' : 'h1'}
            bold={isMobile}
            extraBold={!isMobile}
            onClick={() => onEventClick(navItem.eventId)}
          >
            {navItem.name}
          </Typography>
        ))}
      </HotWrapper>
    </Container>
  );
};
