import { useCallback, useEffect, useState } from 'react';
import { useWindowListener } from './use-window-listener';
import { breakpoints } from '../ui/config/breakpoints';

const INITIAL = {
  xs: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
};

export function useScreenDetect() {
  const [screenType, setScreenType] = useState(INITIAL);

  const detectScreen = useCallback(() => {
    setScreenType({
      xs:
                window.matchMedia(
                  `(max-width: ${parseInt(breakpoints.sm, 10) - 1}px)`,
                ).matches,
      sm:
                window.matchMedia(
                  `(min-width: ${breakpoints.sm})`,
                ).matches
                && window.matchMedia(
                  `(max-width: ${parseInt(breakpoints.md, 10) - 1}px)`,
                ).matches,
      md:
                window.matchMedia(
                  `(min-width: ${breakpoints.md})`,
                ).matches
                && window.matchMedia(
                  `(max-width: ${parseInt(breakpoints.lg, 10) - 1}px)`,
                ).matches,
      lg:
                window.matchMedia(
                  `(min-width: ${breakpoints.lg})`,
                ).matches
                && window.matchMedia(
                  `(max-width: ${parseInt(breakpoints.xl, 10) - 1}px)`,
                ).matches,
      xl:
                window.matchMedia(
                  `(min-width: ${breakpoints.xl})`,
                ).matches,
    });
  }, []);

  useWindowListener('resize', detectScreen);

  useEffect(() => {
    detectScreen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...screenType,
    isMobile: screenType.xs || screenType.sm,
  };
}
