import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import { Spacing } from '../../../../ui/atoms/spacing';
import { Typography } from '../../../../ui/atoms/typography';
import { StyledIcon } from './styles';
import { MenuModal } from '../../../menu/modals/menu-modal';
import { HotModal } from '../../../hot/modals/hot-modal';
import { useHandleEvent } from '../../hooks/use-handle-event';

export const MenuItem = ({
  id, name, eventId, icon,
}) => {
  const [iconUrl, setIconUrl] = React.useState('');
  const handleEvent = useHandleEvent();
  const theme = useTheme();

  useEffect(() => {
    if (icon) {
      const img = new Image();
      img.src = icon;
      img.onload = () => {
        setIconUrl(icon);
      };
    }
  }, [icon]);

  const onClick = () => {
    if (id === 3) {
      MenuModal.open({});
    } else if (id === 2) {
      HotModal.open({});
    } else {
      handleEvent(eventId);
    }
  };

  return (
    <StyledIcon key={name} onClick={onClick}>
      {iconUrl && <img src={iconUrl} alt="icon" />}
      <Spacing mb="2px" />
      <Typography tag="div" size="bodyS" color={theme.colors.$white}>
        {name}
      </Typography>
    </StyledIcon>
  );
};
