import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { modalRegistry } from '../../../modal/providers/modal.registry';
import { FullscreenModalLayout } from '../../../../ui/layouts/fullscreen-modal-layout';
import { StaticPageLayout } from '../../../../ui/layouts/static-page-layout';
import { Typography } from '../../../../ui/atoms/typography';
import { StyledInnerContent } from './styles';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';

const ModalComponent = ({ closeModal, id }) => {
  const { t } = useTranslation(`static-pages/${id}`);
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  useEffect(() => {
    dispatchCompleteEvent({
      id,
      name: FEATURE_NAMES.staticPage,
    });
  }, [dispatchCompleteEvent, id]);

  return (
    <FullscreenModalLayout onClose={closeModal}>
      <StaticPageLayout>
        <Typography tag="div" size="h4" bold center uppercase>
          <div dangerouslySetInnerHTML={{ __html: t('content.primaryHeading') }} />
        </Typography>
        <Typography tag="p">
          <StyledInnerContent dangerouslySetInnerHTML={{ __html: t('content.bodyMain') }} />
        </Typography>
      </StaticPageLayout>
    </FullscreenModalLayout>
  );
};

export const StaticPageModal = modalRegistry.register({
  id: 'StaticPage',
  component: ModalComponent,
});
