import React from 'react';
import PropTypes from 'prop-types';
import { StyledControl, StyledButtonGroup } from './styles';
import { SvgIcon } from '../../atoms/svg-icon';

export const Controls = ({
  muteIconHidden,
  playIconHidden,
  ccIconHidden,
  buttonGroupLeft,
  buttonGroupRight,
  onMuteChange,
  onPlayChange,
  onCCChange,
  play,
  muted,
  cc,
}) => {
  const buttonGroups = {
    left: {
      top: [],
      bottom: [],
    },
    right: {
      top: [],
      bottom: [],
    },
  };

  if (!playIconHidden) {
    buttonGroups.left[buttonGroupLeft].push(
      <StyledControl key="play">
        {/* eslint-disable-next-line max-len */}
        <SvgIcon onClick={() => onPlayChange(!play)} icon={play ? 'media-pause' : 'media-play'} color="white" width={28} />
      </StyledControl>,
    );
  }

  if (!ccIconHidden) {
    buttonGroups.right[buttonGroupRight].push(
      <StyledControl key="cc">
        {/* eslint-disable-next-line max-len */}
        <SvgIcon onClick={() => onCCChange(!cc)} icon={cc ? 'cc-enabled' : 'cc-disabled'} color={cc ? 'white' : 'black'} width={28} />
      </StyledControl>,
    );
  }

  if (!muteIconHidden) {
    buttonGroups.right[buttonGroupRight].push(
      <StyledControl key="mute">
        {/* eslint-disable-next-line max-len */}
        <SvgIcon onClick={() => onMuteChange(!muted)} icon={muted ? 'media-muted' : 'media-unmuted'} color="white" width={28} />
      </StyledControl>,
    );
  }

  return (
    <>
      {Object.entries(buttonGroups.left).map(([position, controls]) => (
        <StyledButtonGroup key={position} $left position={position}>
          {controls}
        </StyledButtonGroup>
      ))}
      {Object.entries(buttonGroups.right).map(([position, controls]) => (
        <StyledButtonGroup key={position} $right position={position}>
          {controls}
        </StyledButtonGroup>
      ))}
    </>
  );
};

Controls.propTypes = {
  muteIconHidden: PropTypes.bool,
  playIconHidden: PropTypes.bool,
  ccIconHidden: PropTypes.bool,
  buttonGroupLeft: PropTypes.oneOf(['top', 'bottom']),
  buttonGroupRight: PropTypes.oneOf(['top', 'bottom']),
  play: PropTypes.bool,
  cc: PropTypes.bool,
  muted: PropTypes.bool,
  onMuteChange: PropTypes.func,
  onPlayChange: PropTypes.func,
  onCCChange: PropTypes.func,
};
