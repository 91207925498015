import styled, { css } from 'styled-components';
import {
  getFontSize,
  getFontWeight,
} from './typography.utils';

const overflowMixin = css`
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: ${({ overflowLines }) => overflowLines ? 'normal' : undefined};
  -webkit-box-orient: ${({ overflowLines }) => overflowLines ? 'vertical' : undefined};
  -webkit-line-clamp: ${({ overflowLines }) => overflowLines || undefined};
`;

export const StyledTypography = styled.span`
  line-height: 120%;
  font-family: ${({ theme, variant }) => variant === 'title' ? theme.fonts.title : theme.fonts.text};
  text-transform: ${({ uppercase, capitalize }) => uppercase && 'uppercase' || capitalize && 'capitalize' || undefined};
  white-space: ${({ breakSpaces }) => breakSpaces ? 'break-spaces' : undefined};
  color: ${({ color, theme }) => theme.colors[color] ?? color};
  
  font-size: ${({ size }) => getFontSize(size)};

  ${({ center }) => center && `
    -webkit-box-pack: center;
    text-align: center;
  `};
  
  word-break: break-word;
  ${({
    bold, semiBold, medium, extraBold,
  }) => getFontWeight({
    extraBold, bold, semiBold, medium,
  })};
  ${overflowMixin}
`;
