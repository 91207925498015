import styled from 'styled-components';
import { media } from '../../mixins/media';

export const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 20px;
  z-index: ${({ theme }) => theme.zIndex.modal};
  overflow-y: auto;
  overflow-x: hidden;
  
  ${media.md`
    padding: 0 40px;
  `}
`;
