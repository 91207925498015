import styled from 'styled-components';
import { media } from '../../mixins/media';

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.$white};
  background: ${({ theme }) => theme.colors.$dark900};
  padding: 100px 20px 70px;
  position: absolute;
  z-index: 3;
  overflow-y: auto;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  
  & p {
    line-height: 24px;
  }

  & a {
    color: #${({ theme }) => theme.colors.$info};
    text-decoration: none;
  }
  
  & > div > * + * {
    margin-top: 20px;
  }
  
  ${media.sm`
    padding: 164px 84px 70px;
  `}
  
  ${media.md`
    & > div {
      max-width: 800px;
      margin: auto;
    }
  `}
`;
