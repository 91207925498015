import styled from 'styled-components';

export const StyledWrapper = styled.button`
  position: absolute;
  z-index: 3;
  top: ${(props) => props.top}%;
  left: ${(props) => props.left}%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s linear;
  
  &:hover {
    background-color:white;
  }
  
  ${({ active }) => active && `
     background-color:white;
  `}
  
  & img {
    width: 25px;
    height: 20px;
  }
`;
