import styled from 'styled-components';

export const CloseNotification = styled.div`
position: absolute;
 cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.$white};
  width: 30px;
  height: 30px;
  top: 4px;
  right: 10px;
`;
export const WrapperNotification = styled.div`
  position: relative;
`;
export const StyledWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  background: rgba(39, 41, 51, 0.9);
  border: 2px solid ${({ theme }) => theme.colors.$dark700};
  box-shadow: 0 3px 0 ${({ theme }) => theme.colors.$dark800};
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 10px;
  left: ${({ closeAnimation }) => closeAnimation ? '400%' : '0px'};
  transition: all 1s ease-in-out;
  & img {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    object-fit: cover;
  }
`;

export const StyledText = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colors.$white};
  margin-left: 10px;
  margin-right: 22px;
  
  & div {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 2px;
  }
  
  & span {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
`;
