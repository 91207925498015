import styled from 'styled-components';
import { media } from '../../../../ui/mixins/media';
import { Typography } from '../../../../ui/atoms/typography';

export const FloorPreviewText = styled(Typography)`
  & span {
      color: ${({ theme }) => theme.colors.$primary200};
      text-decoration: none;
  }
`;

export const StyledPreviewWrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.$dark700};
  box-shadow: 0 10px 0 0 ${({ theme }) => theme.colors.$dark800};
  background-color: ${({ theme }) => theme.colors.$dark900};
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin-top: 50px;
  margin: 20px 27px 0 27px;
  
  ${media.md`
    margin-top: 0;
  `}
  ${media.md`
  padding: 20px;
  margin: 0;
  `}
`;

export const StyledPreviewImage = styled.img`
  width: 100%;
  max-height: 100%;
  border-radius: 20px;
`;

export const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.$white};
  width: 100%;
  
  ${media.md`
    flex: 0 0 400px;
    width: 400px;
  `}
`;

export const LoadingImageStub = styled.div`
  background: ${({ theme }) => theme.colors.$light300};
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-top: 56.25%;
  }
`;

export const StyledLoadingContent = styled.div`
  width: 100%;
  
  & > div:first-child {
    background: ${({ theme }) => theme.colors.$light300};
    border-radius: 6px;
    height: 24px;
    margin-top: 14px;
  }
  
  & > div {
    background: ${({ theme }) => theme.colors.$light200};
    border-radius: 2px;
    height: 10px;
    margin-top: 10px;
  }

  & > div:nth-child(1) {
    width: 50%;
  }
`;
