import React, { useCallback, useState } from 'react';
import { Widget } from '@typeform/embed-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  StyledContent,
  StyledAdvList,
  StyledContentTop,
  StyledDescription,
  StyledFormWrapper,
  StyledTitle,
  StyledWrapper,
} from './styles';
import { LockOverlay } from '../../../../ui/atoms/lock-overlay';
import { useScreenDetect } from '../../../../hocs/use-screen-detect';
import { SvgIcon } from '../../../../ui/atoms/svg-icon';
import { Button } from '../../../../ui/atoms/button';
import { FullscreenModalLayout } from '../../../../ui/layouts/fullscreen-modal-layout';
import { useDispatchCompleteEvent } from '../../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../../config/features.config';
import { MediaUnit } from '../../../../ui/organisms/media-unit';

const CompetitionFormContainer = ({ typeformId }) => {
  const navigate = useNavigate();
  const [visibleForm, setVisibleForm] = useState(false);
  const { lg, xl } = useScreenDetect();
  const { t } = useTranslation(`competitions/${typeformId}`);
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  const mediaItem = t('content.mediaItem', { returnObjects: true })?.[0] || {};

  const onJoin = useCallback(() => {
    setVisibleForm(true);
  }, []);

  const onSubmit = useCallback(() => {
    dispatchCompleteEvent({
      id: typeformId,
      name: FEATURE_NAMES.competition,
    });
  }, [dispatchCompleteEvent, typeformId]);

  const displayForm = visibleForm || lg || xl;

  return (
    <FullscreenModalLayout onClose={() => navigate('/')}>
      <StyledWrapper>
        <StyledContent>
          {displayForm && (
            <StyledFormWrapper>
              <Widget
                id={typeformId}
                style={{ height: '100%' }}
                className="my-form"
                onSubmit={onSubmit}
              />
            </StyledFormWrapper>
          )}
          <div>
            <MediaUnit {...mediaItem} />
            <StyledContentTop>
              <div>
                <StyledTitle>
                  {t('content.primaryHeading')}
                </StyledTitle>
                <StyledAdvList dangerouslySetInnerHTML={{ __html: t('content.bodyHighlights') }} />
              </div>
              <Button onClick={onJoin}>
                <span>{t('content.bodyButtonEnterNowText')}</span>
                <SvgIcon icon="downLeft" width={20} />
              </Button>
            </StyledContentTop>
            <StyledDescription>
              {t('content.bodyDisclaimer')}
            </StyledDescription>
          </div>
        </StyledContent>
      </StyledWrapper>
      <LockOverlay />
    </FullscreenModalLayout>
  );
};

export default CompetitionFormContainer;
