import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import {
  StyledWrapper,
  FloorMap, StyledMapImage,
} from './styles';
import { LocationPinPreview } from '../../components/location-pin-preview';
import { LocationPin } from '../../components/location-pin';
import { FloorPreviewText } from '../../components/location-pin-preview/styles';
import { Spacing } from '../../../../ui/atoms/spacing';
import { useScreenDetect } from '../../../../hocs/use-screen-detect';

export const FloorPlanContainer = ({ id }) => {
  const [selectedPin, setSelectedPin] = useState();
  const { isMobile } = useScreenDetect();
  const { t, ready } = useTranslation(`floorplans/${id}`);
  const theme = useTheme();

  const locationsLoading = !ready;

  const locations = useMemo(() => t('locations', { returnObjects: true }), [t]);

  const mapImage = t('content.floorplanImage.filename');

  useEffect(() => {
    if (locations?.length) {
      setSelectedPin(locations[0]);
    }
  }, [locations]);

  return (
    <Spacing mt={isMobile ? '22px' : '65px'} mb={isMobile ? '22px' : '65px'}>
      <FloorPreviewText tag="div" size={isMobile ? 'h4' : 'h2'} color={theme.colors.$white} extraBold>
        {t('content.headingPrimary')}
      </FloorPreviewText>
      <Spacing mt="10px">
        <FloorPreviewText size="h5" color={theme.colors.$white} bold>
          {t('content.headingSecondary')}
        </FloorPreviewText>
      </Spacing>
      <StyledWrapper>
        <LocationPinPreview
          data={selectedPin}
          featureId={id}
          isLoading={locationsLoading}
          buttonTitle={t('content.discoverButtonText')}
        />
        <FloorMap>
          {mapImage && <StyledMapImage src={mapImage} />}
          {locations.map((location) => (
            <LocationPin
              active={location.name === selectedPin?.name}
              key={location.name}
              location={location}
              onSelect={setSelectedPin}
            />
          ))}
        </FloorMap>
      </StyledWrapper>
    </Spacing>
  );
};
