import styled, { css } from 'styled-components';
import { media } from '../../../../ui/mixins/media';

export const StyledWrapper = styled.div`
  position: fixed;
  bottom: 70px;
  right: 20px;
  left: 20px;
  margin: auto;
  max-width: 400px;
  z-index: 3;

  ${media.md(css`
    top: 40px;
    left: auto;
    bottom: auto;
    margin: unset;
    width: 370px;
  `)}
  
  & > div + div {
    margin-top: 10px;
  }
`;
