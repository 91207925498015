import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  selectEntryPoint,
  selectNavigation, selectProject, selectTheme,
} from '../store/system.selectors';
import { systemSliceActions } from '../store/system.slice';
import { fetchProjectInfoThunk, fetchProjectNavigationThunk } from '../store/system.thunk';

export const useFetchProjectInfo = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchProjectInfoThunk());
  }, [dispatch]);
};

export const useFetchProjectNavigation = () => {
  const dispatch = useDispatch();
  return useCallback((id) => {
    dispatch(fetchProjectNavigationThunk(id));
  }, [dispatch]);
};

export const useAddEventsToQueue = () => {
  const dispatch = useDispatch();
  return useCallback((events = []) => {
    dispatch(systemSliceActions.addEventsToQueue(events));
  }, [dispatch]);
};

export const useTheme = () => useSelector(selectTheme);
export const useEntryPoint = () => useSelector(selectEntryPoint);
/**
 * @returns {ProjectModel}
 */
export const useProject = () => useSelector(selectProject);
export const useNavigation = () => useSelector(selectNavigation);
