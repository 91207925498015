// When adding a new config profile for the media-unit, ensure the profile
// is also added into the config map in the /lib.js file.

export const genericXpDefault = {
  global: {
    buttonGroupRight: 'top',
    muteIconHidden: false,
    buttonGroupLeft: 'top',
    playIconHidden: false,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: false,
    controls: false,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: false,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: false,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    dnt: true,
    muted: false,
    textTrack: false,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: false,
    controls: false,
  },
  imageStoryblok: {
  },
};

export const mediaGalleryXpDefault = {
  global: {
    buttonGroupRight: 'bottom',
    muteIconHidden: false,
    buttonGroupLeft: 'bottom',
    playIconHidden: false,
    enableAutoCarousel: false,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: false,
    controls: false,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: false,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: false,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    dnt: true,
    muted: false,
    textTrack: false,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: false,
    controls: false,
  },
  imageStoryblok: {},
};

export const mediaGalleryXpCcEnabled = {
  global: {
    buttonGroupRight: 'bottom',
    muteIconHidden: false,
    buttonGroupLeft: 'bottom',
    playIconHidden: false,
    enableAutoCarousel: false,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: true,
    controls: false,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: false,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: false,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    dnt: true,
    muted: false,
    textTrack: true,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: false,
    controls: false,
  },
  imageStoryblok: {},
};

export const flickGalleryXpDefault = {
  global: {
    buttonGroupRight: 'bottom',
    muteIconHidden: false,
    buttonGroupLeft: 'bottom',
    playIconHidden: false,
    enableAutoCarousel: true,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: false,
    controls: false,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: false,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: false,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    dnt: true,
    muted: false,
    textTrack: false,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: false,
    controls: false,
  },
  imageStoryblok: {
    imageInterval: 5,
  },
};

export const flickGalleryXpMuted = {
  global: {
    buttonGroupRight: 'bottom',
    muteIconHidden: false,
    buttonGroupLeft: 'bottom',
    playIconHidden: false,
    enableAutoCarousel: true,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: false,
    controls: false,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: true,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: false,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    dnt: true,
    muted: true,
    textTrack: false,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: true,
    controls: false,
  },
  imageStoryblok: {
    imageInterval: 5,
  },
};

export const welcomeXpDefault = {
  global: {
    buttonGroupRight: 'bottom',
    muteIconHidden: false,
    buttonGroupLeft: 'bottom',
    playIconHidden: false,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: false,
    controls: false,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: false,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: false,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    dnt: true,
    muted: false,
    textTrack: false,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: false,
    controls: false,
  },
  imageStoryblok: {
  },
};

export const genericAutoplayMuted = {
  global: {
    buttonGroupRight: 'top',
    muteIconHidden: false,
    buttonGroupLeft: 'top',
    playIconHidden: true,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: false,
    controls: false,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: true,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: false,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    dnt: true,
    muted: true,
    textTrack: false,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: true,
    controls: false,
  },
  imageStoryblok: {
  },
};

export const genericNoIconsPlayerControlsEnabled = {
  global: {
    buttonGroupRight: 'top',
    muteIconHidden: false,
    buttonGroupLeft: 'top',
    playIconHidden: true,
    ccIconHidden: false,
  },
  videoYoutube: {
    autoplay: true,
    showCaptions: false,
    controls: true,
    disableKeyboard: true,
    allowFullscreen: false,
    annotations: false,
    modestBranding: true,
    showRelatedVideos: false,
    muted: false,
  },
  videoVimeo: {
    autoplay: true,
    showByline: false,
    controls: true,
    loop: false,
    showPortrait: false,
    showTitle: false,
    keyboard: false,
    pip: false,
    textTrack: false,
    dnt: true,
    muted: false,
  },
  videoMux: {
    'stream-type': 'on-demand',
    autoplay: true,
    muted: false,
    controls: true,
  },
  imageStoryblok: {
  },
};
