import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { introTourActions } from '../store/intro-tour.slice';
import { selectNotifications } from '../store/notification.selectors';

export const useIntroTourStoreApi = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);

  const setNotifications = useCallback((payload = []) => {
    dispatch(introTourActions.setNotifications(payload));
  }, [dispatch]);

  return {
    notifications,
    setNotifications,
  };
};
