// src/utils/cacheAndVersion.js
import { persistor } from '../store';

export function manageCacheAndVersion() {
  const version = process.env.REACT_APP_PWA_VERSION || '(not set)';
  const versionId = process.env.REACT_APP_PWA_VERSION_ID || '(not set)';
  const versionSha = process.env.REACT_APP_PWA_VERSION_SHA || '(not set)';
  console.log(`xSpace Version (current):\nVersion: ${version}\nRelease: ${versionId}\nSHA: ${versionSha}`);

  const lsVersionName = localStorage.getItem('versionName') || '(not set)';
  const lsVersionId = localStorage.getItem('versionId') || '(not set)';
  const lsVersionSha = localStorage.getItem('versionSha') || '(not set)';
  console.log(`xSpace Version (cached):\nVersion: ${lsVersionName}\nRelease: ${lsVersionId}\nSHA: ${lsVersionSha}`);

  if (lsVersionId !== '(not set)' && versionId !== '(not set)') {
    if (lsVersionId === versionId) {
      console.log('Debug: PWA version matches cache.');
    } else if (process.env.REACT_APP_PWA_ENV !== 'main' && process.env.REACT_APP_PWA_ENV !== null) {
    /* eslint-disable-next-line no-alert */
      const reload = window.confirm('Your cache is outdated, would you like to load the latest version?');
      if (reload) {
        persistor.flush().then(() => {
          persistor.purge().then(() => {
            Object.keys(localStorage).forEach((key) => key !== 'persist:root' && localStorage.removeItem(key));

            if ('caches' in window) {
              caches.keys().then((names) => {
                names.forEach((name) => caches.delete(name));
              });
            }

            if (navigator.serviceWorker) {
              navigator.serviceWorker.getRegistrations().then((registrations) => {
                registrations.forEach((registration) => registration.unregister());
              });
            }

            window.location.reload(true);
          });
        });
      } else {
        console.log('Debug: Version mismatch, user instruction do not update.');
      }
    }
  }

  localStorage.setItem('versionName', version);
  localStorage.setItem('versionId', versionId);
  localStorage.setItem('versionSha', versionSha);
}
