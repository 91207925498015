import {
  flickGalleryXpDefault,
  genericXpDefault,
  mediaGalleryXpDefault,
  welcomeXpDefault,
  genericAutoplayMuted,
  genericNoIconsPlayerControlsEnabled,
  flickGalleryXpMuted,
  mediaGalleryXpCcEnabled,
} from './config';

const CONFIG_MAP = {
  mediaGalleryXpDefault,
  flickGalleryXpDefault,
  welcomeXpDefault,
  genericXpDefault,
  genericAutoplayMuted,
  genericNoIconsPlayerControlsEnabled,
  flickGalleryXpMuted,
  mediaGalleryXpCcEnabled,
};

export const getMediaConfig = (common, media) => {
  const name = media.mediaEmbedConfigProfile || common;
  const profile = CONFIG_MAP[name] || CONFIG_MAP.mediaGalleryXpDefault;
  let config = profile.imageStoryblock;
  if (media.videoPlatform === 'vimeo') {
    config = profile.videoVimeo;
  }
  if (media.videoPlatform === 'youtube') {
    config = profile.videoYoutube;
  }

  if (media.videoPlatform === 'mux') {
    config = profile.videoMux;
  }

  return {
    global: profile.global,
    config,
  };
};

export const playTestVideo = () => {
  /* Create a new <video /> element */
  const testVideo = document.createElement('video');
  const style = {
    position: 'absolute',
    visibility: 'hidden',
  };
  Object.assign(testVideo.style, style);

  /* Use a short base64-encoded clip */
  testVideo.src = '/test.mp4';
  testVideo.volume = 0.1;
  testVideo.playsInline = true;

  /* Append to page body */
  document.body.appendChild(testVideo);

  /* See if it autoplays */
  const itPlays = testVideo.play();

  return itPlays.then(() => {
    document.body.removeChild(testVideo);
    return true;
  })
    .catch(() => {
      document.body.removeChild(testVideo);
      console.log('Autoplay was prevented.');
      return false;
    });
};
