import React, { useCallback, useMemo } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { modalRegistry } from '../../providers/modal.registry';
import { StyledWrapper } from './modal-component.styles';
import { ModalComponentContext } from '../../providers/modal-component.context';

const ModalComponent = ({ modal, isLast }) => {
  const {
    id, preventEasyClose, component: Component, opened, props: modalProps,
  } = modal;

  const value = useMemo(() => ({ modal }), [modal]);

  const closeModal = useCallback(() => {
    modalRegistry.get(id)?.close();
  }, [id]);

  const onOverlayClick = useCallback((e) => {
    const isLang = e?.target?.closest('[data-component="lang-switcher"]');
    // eslint-disable-next-line no-unused-expressions
    !preventEasyClose && !isLang && closeModal();
  }, [preventEasyClose, closeModal]);

  if (!Component || !opened) {
    return null;
  }

  return (
    <StyledWrapper>
      <OutsideClickHandler disabled={!isLast} onOutsideClick={onOverlayClick} display="contents">
        <ModalComponentContext.Provider value={value}>
          <Component {...modalProps} closeModal={closeModal} />
        </ModalComponentContext.Provider>
      </OutsideClickHandler>
    </StyledWrapper>
  );
};

export default ModalComponent;
