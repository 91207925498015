import { useCallback, useState } from 'react';
import { NewsletterAPI } from '../../../api/newsletter';
import { useDispatchCompleteEvent } from '../../../hooks/use-dispatch-complete-event';
import { FEATURE_NAMES } from '../../../config/features.config';

export const useSubscribe = (id) => {
  const [sent, setSent] = useState(false);
  const [sending, setIsSending] = useState(false);
  const dispatchCompleteEvent = useDispatchCompleteEvent();

  const subscribe = useCallback(async (data) => {
    setIsSending(true);
    try {
      await NewsletterAPI.subscribe({
        id,
        body: {
          emailAddress: data.email,
          consent: true,
        },
      });
      setSent(true);
      dispatchCompleteEvent({
        id,
        name: FEATURE_NAMES.newsletter,
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsSending(false);
    }
  }, [id, dispatchCompleteEvent]);

  return {
    sent,
    sending,
    subscribe,
  };
};
