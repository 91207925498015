import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StylesSelect } from './styled';
import { STORAGE_KEYS } from '../../../../config/common.config';
import { useProject } from '../../hooks/use-system-store';

export const LanguageSwitcher = ({ welcomeMode, languageSelector, language }) => {
  const { i18n } = useTranslation();
  const project = useProject();
  const { locales } = project.global;

  const handleSelect = useCallback(
    (e) => {
      const { value } = e.target;
      i18n.changeLanguage(value);
      localStorage.setItem(STORAGE_KEYS.lang, value);
      window.location.hash = `#language=${value}`;
    },
    [i18n],
  );

  return (
    <StylesSelect
      welcomeMode={welcomeMode}
      onChange={handleSelect}
      defaultValue={language || i18n.language}
    >
      {languageSelector ? (
        <option value={language}>{locales[language]}</option>
      ) : (
        <>
          {Object.keys(locales).map((langKey) => (
            <option key={langKey} value={langKey}>
              {locales[langKey]}
            </option>
          ))}
        </>
      )}
    </StylesSelect>
  );
};
