import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LiveFeedItem } from '../../components/live-feed-item';
import { useMessages, useMessagesLoading } from '../../hooks/use-live-stream-messages';
import {
  StyledFeedList, StyledFrame, StyledHead, StyledLoading, StyledTitleHead,
} from './styles';
import { LiveStreamDetailsContainer } from '../live-stream-details-container';
import { LiveStreamSignInContainer } from '../live-stream-sign-in-container';
import { useScreenDetect } from '../../../../hocs/use-screen-detect';

const mock = {
  author: 'Helen Yu',
  nickName: '@HelenYu',
  text: 'Totally in love with breakdancing! This xSpace Live Stream is 🔥 '
  + 'https://pwa.xspace.dev/broadcast @cincoagency #breakdancing',
  createdAt: 'Now',
  avatar: '/icons/avatar-person-1.svg',
  link: 'https://wearecinco.com',
};

export const LiveFeedContainer = ({ id }) => {
  const messages = useMessages();
  const loading = useMessagesLoading();
  const { isMobile } = useScreenDetect();
  const { t } = useTranslation(`livestream-twitter/${id}`);

  const [signed, setSigned] = useState(false);

  return (
    <StyledFrame>
      <StyledTitleHead alignItems="center">
        <img src={t('content.chatSectionIcon.filename')} width={30} />
        <span>{t('content.chatSectionTitle')}</span>
        {loading && (
          <StyledLoading alignItems="center">
            <span>{t('content.twitterLoaderText')}</span>
            <img src="/icons/loading.svg" />
          </StyledLoading>
        )}
      </StyledTitleHead>

      {signed ? (
        <>
          {isMobile ? (
            <StyledHead alignItems="center">
              <img src="/icons/twitter-button.svg" width={30} />
              <span>Live Feed</span>
              {loading && (
                <StyledLoading alignItems="center">
                  <span>{t('content.twitterLoaderText')}</span>
                  <img src="/icons/loading.svg" />
                </StyledLoading>
              )}
            </StyledHead>
          ) : (
            ''
          )}
          <LiveStreamDetailsContainer id={id} />
        </>
      ) : (
        <LiveStreamSignInContainer id={id} onSignIn={() => setSigned(true)} />
      )}

      {isMobile && !signed ? (
        <StyledHead alignItems="center">
          <img src="/icons/twitter-button.svg" width={30} />
          <span>Live Feed</span>
          {loading && (
            <StyledLoading alignItems="center">
              <span>{t('content.twitterLoaderText')}</span>
              <img src="/icons/loading.svg" />
            </StyledLoading>
          )}
        </StyledHead>
      ) : (
        ''
      )}

      <StyledFeedList>
        {messages.map((msg) => (
          <LiveFeedItem key={msg.id} {...msg} />
        ))}
        <LiveFeedItem {...mock} />
        <LiveFeedItem {...mock} avatar="/icons/user-2.svg" />
        <LiveFeedItem {...mock} />
      </StyledFeedList>
    </StyledFrame>
  );
};
