/**
 * EXAMPLE
 *
 * styled.div`
 *   ${media.md`
       background-color: aqua;
     }
 * `
 */
import { css } from 'styled-components';
import { breakpoints } from '../config/breakpoints';

export const media = Object.keys(breakpoints).reduce(
  (accumulator, label) => {
    accumulator[label] = (...args) => css`
      @media ( min-width: ${breakpoints[label]} ) {
          ${css(...args)};
        }
      `;
    return accumulator;
  },
  {},
);
